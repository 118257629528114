<template>
  <section
    v-editable="blok"
    class="section"
    :class="[
      blok.theme ? `section--${blok.theme}` : '',
      { 'banner-section': hasBanner },
    ]"
  >
    <template v-if="blok.components">
      <StoryblokComponent
        v-for="component in blok.components"
        :key="component._uid"
        :blok="component"
      />
    </template>
    <template v-else>
      <slot />
    </template>
  </section>
</template>

<script lang="ts" setup>
import { GlobalSectionBlokProps } from '@/types';

// Props
defineProps<{
  blok: GlobalSectionBlokProps;
}>();
const hasBanner = ref(false);
onMounted(() => {
  const bannerExists = !!document.getElementById('navbarBanner');
  hasBanner.value = !!bannerExists;
});
</script>

<style lang="scss">
.section {
  background-position: top center;
  background-repeat: repeat;
  flex-grow: 1;
  margin-block-end: calc(var(--section-border-radius) * -1);
  padding-block: 0 var(--section-border-radius);
  padding-inline: 0;

  &:first-of-type {
    padding-block-start: var(--header-height);
  }

  &:not(:first-of-type) {
    border-radius: var(--section-border-radius) var(--section-border-radius) 0 0;
  }

  &--dark-green {
    background-color: var(--dark-green);
    background-image: url('/img/textures/texture-1.png');
  }

  &--dark-gray {
    --highlight: var(--bright-blue);

    background-color: var(--dark-gray);
    background-image: url('/img/textures/texture-2.png');
  }

  &--chalk {
    --darkest: var(--white);
    --dark: var(--chalk);
    --light: var(--dark-green);
    --lightest: var(--dark-gray);
    --neutral: var(--neutral-2);

    background-color: var(--chalk);
    background-image: url('/img/textures/texture-3.png');
    color: var(--lightest);
  }
}

.banner-section {
  &:first-of-type {
    padding-block-start: var(--banner-header-height);
  }
}
</style>
